<template>
  <list-container-widget id="votes" :app-id="106" stretch class="d-flex">
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="votes" />
    </template>
    <template>
      <div v-if="isStaff" class="mb-1 mb-md-3">
        <div v-if="items && items.length > 0">
          <ul ref="container" class="w-100 pl-0">
            <li
              v-for="(item, index) in items"
              :key="index"
              class="x-scroll-element pt-2 mb-1 p-0 border-bottom-0 border-left-0 border-right-0 border list-group-item px-1"
            >
              <Voting :item="item" @data="fetchData(true)" />
            </li>
          </ul>
        </div>
        <div v-else>
          <b-row class="horizontal-placeholder">
            <b-col cols="12">
              <img :src="urnaPlaceholder" />
            </b-col>
            <b-col cols="12" style="padding: 0px !important">
              <p class="text-primary">
                {{ $t("votes.no-available") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else-if="!onGoingVotation && !finished">
        <b-row class="horizontal-placeholder">
          <b-col cols="12">
            <img :src="entityPlaceholder" />
          </b-col>
          <b-col cols="12" style="padding: 0px !important">
            <p class="text-primary">
              {{ $t("votes.yet") }}
            </p>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <questions-votes
          v-if="actualForm != null"
          :actual-num="actualQuestionIndex"
          :actual="actualQuestion"
          :items="formObject"
          :item="actualQuestion"
          :finished="finished"
          @data="fetchData(true)"
        />
        <div v-else>
          <b-row class="horizontal-placeholder">
            <b-col cols="12">
              <img :src="entityPlaceholder" />
            </b-col>
            <b-col cols="12" style="padding: 0px !important">
              <p class="text-primary">
                {{ $t("votes.yet") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <create-votes-modal />
      <unshare-modal
        v-model="isUnshareModalVisible"
        model-type="vote"
        @remove-item="handleRemoveItem"
      />
    </template>
  </list-container-widget>
</template>

<script>
import FaqAccordion from "@/views/apps/faqs/components/FaqAccordion.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import FormTranslationTable from "@core/components/form-translation-table/FormTranslationTable.vue";
import EventsPlaceholder from "@/assets/images/placeholders/light/vote-time.svg";
import UrnaPlaceholder from "@/assets/images/placeholders/light/urna.svg";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import CreateVotesModal from "@/@core/components/modal/CreateVotesModal.vue";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import EditVotesModal from "@/@core/components/modal/EditVotesModal.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import QuestionsVotes from "@/views/votes/QuestionsVotes.vue";
import Voting from "@/views/votes/Voting.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  components: {
    FaqAccordion,
    FormTranslationTable,
    ListContainerWidget,
    Voting,
    WidgetActions,
    CreateVotesModal,
    EditVotesModal,
    UnshareModal,
    QuestionsVotes,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: [],
      searchInput: "",
      results: [],
      keyup: null,
      open: false,
      finished: false,
      onGoingVotation: false,
      isUnshareModalVisible: false,
      itemDelete: null,
      actualForm: null,
      actualQuestion: null,
      options: [{}, {}],
      question: {},
      actualQuestionIndex: 0,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    entityPlaceholder() {
      return EventsPlaceholder;
    },
    urnaPlaceholder() {
      return UrnaPlaceholder;
    },
       isStaff() {
      return this.collective.isStaff;
    },
    canAdd() {
      return (
        checkPermissions(
          "create",
          "vote",
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    itemsData() {
      return this.$store.getters.votes;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    formObject() {
      return this.items.find((element) => element.key === this.actualForm);
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
  },
  async created() {
    this.$socket.emit("joinVote", this.$store.getters.currentCollective.slug);
    await this.fetchData();
    await this.checkIsOngoingVotation();
  },
  sockets: {
    connect(data) {
      this.$socket.emit("joinVote", this.$store.getters.currentCollective.slug);
      this.checkIsOngoingVotation();
    },
    onGoingVotation(data) {
      this.onGoingVotation = data;
      return data;
    },
    startedVote(data) {
      this.finished = false;
    },
    totalAnswers(data) {
      this.totalAnswers = data;
      return data;
    },
    actualQuestion(data) {
      this.actualQuestion = data;
      return data;
    },
    actualForm(data) {
      this.actualForm = data;
      return data;
    },
    finished(data) {
      this.actualQuestion = null;
      this.actualQuestionIndex = 0;
      this.finished = true;
      return data;
    },
    actualQuestionNumber(data) {
      this.actualQuestionIndex = data;
    },
  },
  methods: {
    isQuestionsShown(key, status) {
      if (status) {
        this.$set(this.statusQuestionsShown, key, true);
      } else {
        this.statusQuestionsShown[key] = false;
      }
    },
    async checkIsOngoingVotation() {
      this.$socket.emit("isOngoingVotation", {
        communitySlug: this.$store.getters.currentCollective.slug,
      });
      this.$socket.emit("getActualForm", {
        communitySlug: this.$store.getters.currentCollective.slug,
      });
      this.$socket.emit("getActualQuestion", {
        communitySlug: this.$store.getters.currentCollective.slug,
      });
      this.$socket.emit("getActualQuestionNumber", {
        communitySlug: this.$store.getters.currentCollective.slug,
      });
      await this.fetchData(true);
    },
    resetInputs() {
      this.$bvModal.show("modal-create-votes");
    },
    async fetchData(force = false) {
      await this.$store.dispatch("getItems", {
        itemType: "forms",
        page: 1,
        forceAPICall: force,
        customName: "votes",
        requestConfig: {
          type: "vote",
          orderByDate: -1,
        },
      });

      this.isLoading = false;
    },
    async handleRemoveItem(response) {
      const temo = {
        key: this.itemDelete,
      };
      if (response === true) {
        try {
          await this.$store.dispatch("unshareProject", {
            item: temo,
            type: "forms",
            tableName: "votes",
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.success", {
                item: this.$t("unshare.singular-items.vote"),
              }),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.error", {
                item: this.$t("unshare.singular-items.vote"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-logo {
  max-width: 16rem;
  min-width: 10rem;
}
.card-standard {
  width: 90% !important;
}
</style>
